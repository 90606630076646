<template>
  <v-card  id="stepfooter-dashboard-page" class="stepfooter-page ma-0 pa-3 d-flex align-center justify-space-between" tile color="primary">
    <v-row class="ma-0 pa-0">
      <v-col cols="3" class="pa-0 d-flex align-center">
        <v-btn color="secondary" class="font-weight-bold" :disabled="currentPage === lastPage" v-if="!donorCatchUpSfId && currentPage !== 1"
          elevation="2" @click="currentPage !== lastPage && $emit('back')" rounded>
            <v-icon left dark>mdi-chevron-left</v-icon> Back
        </v-btn>
      </v-col>
      <v-col cols="6" class="pa-0">
        <v-row align="center" justify="space-around">
          <div v-if="$helpers.getCurData('curUserPermissions').indexOf('flow_advanced_options') !== -1 && currentPage !== lastPage" class="font-weight-medium d-flex flex-column white--text cursor-pointer" @click="openAdvOptModal=true">
            <v-icon dark color="iconbg" class="mb-1">mdi-clipboard-list-outline</v-icon>
            <small>Options</small>
          </div>
          <div :class="$helpers.getCurData('curUserPermissions').indexOf('flow_advanced_options') === -1 && 'ml-5'" class="font-weight-medium d-flex flex-column white--text cursor-pointer" @click="$emit('followUP')">
            <v-icon dark color="iconbg" class="mb-1">mdi-face-man</v-icon>
            <small>Create Follow Up</small>
          </div>
          <div class="font-weight-medium d-flex flex-column white--text cursor-pointer" @click="openPreferModal=true">
            <v-icon dark color="iconbg" class="mb-1">mdi-mail</v-icon>
            <small>Preferences</small>
          </div>
          <div class="font-weight-medium d-flex flex-column white--text cursor-pointer"
            @click="openInfoModal=true">
            <v-icon dark color="iconbg" class="mb-1">mdi-information</v-icon>
            <small>Info</small>
          </div>
          <div @click="toggleDonotRead" class="font-weight-medium d-flex flex-column white--text cursor-pointer">
            <v-icon :color="model.do_not_read === 1 ? 'danger' : 'iconbg'" dark class="mb-1">mdi-close-octagon</v-icon>
            <v-chip v-if="model.do_not_read === 1" class="font-weight-bold" x-small text-color="danger" color="white">Do Not Read</v-chip>
            <small v-else>Do Not Read</small>
          </div>
          <div v-if="!donorCatchUpSfId" :class="$helpers.getCurData('curUserPermissions').indexOf('flow_advanced_options') === -1 && 'mr-5'"
            class="font-weight-medium d-flex flex-column white--text cursor-pointer" @click="resetForm">
            <v-icon dark color="iconbg" class="mb-1">mdi-refresh</v-icon>
            <small>Restart</small>
          </div>
        </v-row>
      </v-col>
      <v-col cols="3" class="pa-0 d-flex align-center justify-end">
        <v-btn color="secondary" class="font-weight-bold" :loading="storyLoading || stripLoading"
          elevation="2" type="submit" rounded :disabled="(currentPage === 3 && !model.cardValidate) || slackLoading || stripLoading">
          {{(currentPage === 3 || (currentPage === 3 && ['CQ', 'C', 'DT'].indexOf(model.paymentMethod) !== -1)) ? donorCatchUpSfId ? 'Submit' : 'Process' : (currentPage === lastPage ? 'Submit' : 'Next')}}
            <v-icon right dark>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <CommPreferenceModal v-if="openPreferModal" :modalOpen="openPreferModal" @closeModal="openPreferModal=false" />
      <InfoModal v-if="openInfoModal" :modalOpen="openInfoModal" @closeModal="openInfoModal=false" />
      <AdvanceOptionsModal v-if="openAdvOptModal" :modalOpen="openAdvOptModal" @close="openAdvOptModal=false" />
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "stepFooter",
  props: ["currentPage", "v", "slackLoading", "storyLoading", "donorCatchUpSfId"],
  emits: ["followUP", "goHome"],
  components: {
    AdvanceOptionsModal: () => import("@/pages/dashboard/components/AdvanceOptions"),
    CommPreferenceModal: () => import("@/pages/dashboard/components/PreferenceModal"),
    InfoModal: () => import("@/pages/dashboard/components/InfoModal"),
  },
  data: (vm) => ({
      openPreferModal: false,
      openInfoModal: false,
      openAdvOptModal: false,
      lastPage: 4
  }),
  computed: {
    ...mapGetters(["model", "donatePaymentData", "stripLoading"])
  },
  methods: {
    ...mapActions(["updateDonate"]),

    toggleDonotRead() {
      if (this.model.do_not_read === 1) {
        this.model.do_not_read = 0;
      } else {
        this.model.do_not_read = 1;
      }
      const donateId = this.$helpers.getCurData("curDonate");
      if (donateId) {
        const reqData = {
          id : donateId,
          do_not_read: this.model.do_not_read
        }
        this.updateDonate(reqData);
      }
      this.$store.commit("updateModel", this.model);
    },
    resetForm() {
      if (localStorage.getItem("pageLock") && localStorage.getItem("pageLock") === 'true') {
        localStorage.removeItem("pageLock");
        this.$router.push('info');
      }
      this.$store.dispatch('refreshData');
      this.$emit('goHome', true);
      this.$helpers.removeLSStorage('curDonate');
    }
  }
};
</script>
